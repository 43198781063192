// $(function(){
//   const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
//
// const btn = document.querySelector(".toggle");
// // Listen for a click on the button
// btn.addEventListener("click", function() {
//   // If the user's OS setting is dark and matches our .dark-mode class...
//   if (prefersDarkScheme.matches) {
//     // ...then toggle the light mode class
//     document.body.classList.toggle("dark-mode");
//     // ...but use .dark-mode if the .light-mode class is already on the body,
//     var theme = document.body.classList.contains("light-mode") ? "light" : "dark";
//   } else {
//     // Otherwise, let's do the same thing, but for .dark-mode
//     document.body.classList.toggle("dark-mode");
//     var theme = document.body.classList.contains("dark-mode") ? "dark" : "light";
//   }
//   // Finally, let's save the current preference to localStorage to keep using it
//   localStorage.setItem("theme", theme);
// });
// })
